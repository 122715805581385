<template>
  <component :is="tag" v-if="site && site.hostingData" v-bind="$attrs">
    <loading v-if="$_.isEmpty(site) || loading" />
    <div v-else :style="{ position: 'relative' }">
      <button
        v-for="(hosting, index) in site.hostingData"
        :key="`login-${index}`"
        class="button is-light fxd-external-login"
        :class="{ 'is-loading': hosting.loading }"
        @click="loginCPanel(hosting)"
      >
        <u class="is-size-7">{{ hosting.hostname }}</u>
        <i class="fab fa-cpanel fa-2x" />
      </button>
    </div>
  </component>
</template>

<script>
export default {
  name: "SiteExternalLogins",
  props: {
    site: {
      required: true,
      type: Object
    },
    tag: {
      default: "div",
      type: String
    }
  },
  data() {
    return {
      loading: true,
      loadingCPanelLink: false,
      hostingInfo: null
    };
  },
  created() {
    this.setHostingInfo().finally(() => {
      this.loading = false;
    });
  },
  methods: {
    setHostingInfo() {
      // Cache hosting data for 1h
      if (
        !this.site.hostingDateUpdated ||
        this.$moment(this.site.hostingDateUpdated.toDate())
          .add(1, "hour")
          .isBefore(new Date())
      ) {
        return this.$store
          .dispatch("sites/setHostingInfo", {
            siteId: this.site._id
          })
          .catch(() => {
            //hosting for site not found
          });
      }
      return Promise.resolve();
    },
    loginCPanel(hosting) {
      if (hosting.loading) return;
      this.$set(hosting, "loading", true);
      return this.$store
        .dispatch("sites/callExternalAPI", {
          url: hosting.loginlink
        })
        .then(response => {
          if (response.status === 200) {
            window.open(response.data, "_blank");
          }
        })
        .catch(() => {
          this.$toast.open({
            message: "Error logging into cPanel",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.$set(hosting, "loading", false);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@sass/bulma/custom-variables";
.fxd-external-login {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  u {
    text-overflow: ellipsis;
    overflow: hidden;
  }
  i {
    margin-left: 1rem;
  }
}
</style>
